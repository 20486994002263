import React from 'react';
import { Container } from 'react-bootstrap';
import '../App.css';
import Nav from '../components/Header/Header.tsx';



export function Header() {

 return(

 <Container>
  <Nav></Nav>
 </Container>
  );
}
