import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Redesocial from "../components/Footer/RedeSociais.tsx";

export function Footer() {
  return (
    <Container style={{ 
      height: "20vh",
      padding: "10px", 
      justifyContent: 'center', 
      alignItems: 'center', 
      borderColor: 'black', 
      zIndex: 1000,
      marginTop: 100,
     
    }}>
      <Row>
        <Col lg={4} sm={12}>
        <Redesocial></Redesocial>
        </Col>
        <Col lg={4} sm={12} style={{textDecoration: 'none'}}>

              
            
        </Col>
        <Col lg={4} sm={12}>
         
        </Col>
      </Row>
    </Container>
  );
}