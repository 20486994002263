import AOS from 'aos';
import React, { useEffect } from "react";
import { SkillsLista } from '../Skills/Lista-skills.tsx';
import CardSkills from "../ui/Card/skills.tsx";

const Skills = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div
      style={{ marginTop: 100, overflow: 'hidden' }}
      className="flex flex-col justify-center items-center align-middle w-100 min-h-[90vh] gap-[5rem] p-4 bg-[#212121]"
    >
      <h2
        className="font-montserrat font-bold text-center text-maincolor text-4xl"
        data-aos="fade-up"
      >
        Skills
      </h2>
      <div
        className="grid md:grid-cols-2 xl:grid-cols-3 gap-[3rem]"
        data-aos="slide-up"
      >
        {SkillsLista.map(({ title, img: Icon }, index) => (
          <CardSkills
            key={index}
            title={title}
            img={Icon}
          />
        ))}
      </div>
    </div>
  );
};

export default Skills;
