import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer.tsx";
import { Header } from './Header.tsx';

export default function Body() {
    return (
      <>
          <Header/>
          <Outlet></Outlet>
          <Footer/>
      </>
    );
}
