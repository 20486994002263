import React from "react";
import { Container } from "react-bootstrap";
import Formulario from "../../components/ui/Formulario/Formulario.tsx";

export default function Contatos() {
  return (
    <Container style={{marginTop: 120}}>
    
        <Formulario></Formulario>
      
    </Container>
  );
}