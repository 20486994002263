import emailjs from 'emailjs-com'; // Import emailjs library
import React, { FormEvent, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from '../Button/index.tsx';

function FormFloatingBasicExample() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [btnText, setBtnText] = useState('Enviar');
  const [error, setError] = useState('');

  const formRef = useRef<HTMLFormElement | null>(null); // Correção: especificar o tipo

  const sendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setBtnText('Enviando...');

    if (name && email && msg) {
      setError('');
      try {
        await emailjs.sendForm(
          'service_felgnko',
          'template_31pelei',
          formRef.current!, // Correção: usar o operador "!" para garantir que não seja nulo
          '4VQ7DIqu-yhhnT3CK'
        );
        console.log('Email enviado com sucesso');
        setBtnText('Enviado!');
      } catch (error) {
        console.error('Erro ao enviar o email:', error);
        setBtnText('Enviar');
      }
    } else {
      setBtnText('Enviar');
      setError('Preencha todos os campos.');
    }
  };

  return (
    
    <form style={{ height: '100%', textAlign:'center', alignItems: 'center', justifyContent: 'center'}} onSubmit={sendEmail} ref={formRef} data-aos="fade-up">
    
    <Col style={{ textAlign:'center', justifyContent: 'center', backgroundColor: '#212121', padding: '30% 10%'}}>
    <h2 className="font-montserrat font-bold text-center text-2xl sm:text-4xl"><span style={{
                    position: 'relative',
                    zIndex: 1,
                    background: 'linear-gradient(to top, #F55F1B, #212121)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    display: 'inline-block',
                    paddingTop: '20px',
                }}>
                    Entre em contato
                </span></h2>
      <FloatingLabel style= {{marginTop: "2%"}}  controlId="floatingInputEmail" label="E-mail">
        <Form.Control
          type="email"
          className="focusedInput"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
      </FloatingLabel>
      <FloatingLabel style= {{marginTop: "2%"}}  controlId="floatingInputName" label="Nome">
        <Form.Control
          type="text"
          className="focusedInput"
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
      </FloatingLabel>
      <FloatingLabel style= {{marginTop: "2%"}} controlId="floatingInputTitle" label="Mensagem">
    <Form.Control style= {{height:'100px'}}
          as="textarea"
          className="focusedInput"
          value={msg}
          onChange={({ target }) => setMsg(target.value)}
        />
      </FloatingLabel>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Row style={{alignItems: 'center', padding: '5% 33%'}}> 

      <Button size="md" type="submit">
        {btnText}
      </Button>
      </Row>
    
     </Col>
    </form>
  );
}

export default FormFloatingBasicExample;
