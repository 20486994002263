import React from 'react';
import { Card } from "react-bootstrap";

type CardProps = {
  title: string;
  img: React.ComponentType<{ size: number; className?: string }>; // Corrigido o tipo para aceitar as propriedades size e className
};

const CardSkills: React.FC<CardProps> = ({ title, img: Icon }) => (
  <div className="justify-center items-center bg-white rounded-[10px] shadow border border-neutral-100 flex-col inline-flex p-4 gap-4 justify-around w-[350px]">
    <div className="flex-col justify-start items-start gap-2 inline-flex">
      <h3 className="text-[#333] text-2xl font-bold font-montserrat">
        {title}
      </h3>
    </div>
    <div className="flex-col justify-between items-start align-middle inline-flex text-justify">
      <Card className="hover:text-maincolor border-0">
        <Icon size={80} className="transition-colors" />
      </Card>
    </div>
  </div>
);

export default CardSkills;
