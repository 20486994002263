import { BiLogoPostgresql } from "react-icons/bi";
import { FaBootstrap, FaDocker, FaFigma, FaJava, FaReact } from "react-icons/fa";
import { IoLogoGithub, IoLogoJavascript } from "react-icons/io";
import { IoLogoCss3, IoLogoFirebase, IoLogoHtml5 } from "react-icons/io5";
import { RiTailwindCssFill } from "react-icons/ri";
import { SiJest, SiTypescript } from "react-icons/si";


    export const SkillsLista = [
        {
            title: 'GitHub',
            img: IoLogoGithub
        }
        ,
        {
            title: 'Figma',
            img: FaFigma
        }
        ,
        {
            title: 'Docker',
            img: FaDocker
        }
        ,
        {
            title: 'Postgresql',
            img: BiLogoPostgresql
        }
        ,
        {
            title: 'Firebase',
            img: IoLogoFirebase
            
        }
        ,
        {
            title: 'TypeScript',
            img: SiTypescript
        }
        ,
        {
            title: 'Tailwind',
            img: RiTailwindCssFill
        }
        ,
        {
            title: 'Jest',
            img: SiJest
        }
        ,
        {
            title: 'Bootstrap',
            img:FaBootstrap
        }
        ,
        {
           title: 'React',
           img: FaReact
        },
        {
            title: 'Html',
            img: IoLogoHtml5
        },
        {
          title: 'JavaScript',
          img: IoLogoJavascript
        },
        {
          title: 'HTML5',
          img: FaJava
        },
        {
          title: 'CSS3',
          img: IoLogoCss3
        }
      ];



