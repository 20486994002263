import React from 'react';
import Biografia from "../components/Biografia/Biografia.tsx";
export default function PageBiografia() {


  return (
 
    
        <Biografia />
   
      
   
  );
}
