import { default as React } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Body from "../layout/Body.tsx";
import Biografia from '../pages/Biografia.tsx';
import Contatos from '../pages/Contatos.tsx';
import LandingHome from '../pages/LandingHome.tsx';
import Projeto from '../pages/Projeto.tsx';
import Skills from '../pages/Skills.tsx';

function App() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Body />}>
            <Route index element={<LandingHome />} />
            <Route path="biografia" element={<Biografia />} />
            <Route path="skills" element={<Skills/>} />
            <Route path="projetos" element={<Projeto/>} />
            <Route path="contatos" element={<Contatos />} />
          </Route>
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </Router>
    );
  }
  
  export default App;
  