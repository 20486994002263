import React from "react";
import { Card, Row } from "react-bootstrap";
import { FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import '../../App.css';

export default function redesocial() {
  return (
    <Card className="quadro">
       <h5 className="font-montserrat text-xl sm:text-2xl py-1">Redes sociais</h5>
      <Row>
        <div
          className="box-whats"
          onClick={() => (window.location.href = "https://api.whatsapp.com/send?phone=47991518118")}
        >
          <FaWhatsapp size={20} />
        </div>
        <div
          className="box-instagram"
          onClick={() => (window.location.href = "https://www.instagram.com/eduardohansen.dev/")}
        >
          <FaInstagram size={20} />
        </div>
        <div 
          className="box-face "
          onClick={() => (window.location.href = "https://www.facebook.com/eduardo.eduardohansen")}
        >
          <FaFacebookF size={20} />
        </div>
        <div
          className="box-link"
          onClick={() => (window.location.href = "https://www.linkedin.com/in/eduardo-hansen-520164213/")}
        >
          <FaLinkedinIn size={20} />
        </div> 
        
      </Row>
    </Card>
  );
}
