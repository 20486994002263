import React from "react";
import { Card } from "react-bootstrap";
import Biografia from "../Biografia/Biografia.tsx";
import Contatos from "../Contatos/Contatos.tsx";
import Projetos from "../Projetos/index.tsx";
import Skills from "../Skills/Skills.tsx";


export default function LandingHome() {
  return (
    <Card style={{borderRadius: 'none', borderColor:'none'}}> 
      <Biografia></Biografia>
      <Skills></Skills>
      <Projetos></Projetos>
      <Contatos></Contatos>
    </Card>
  );
}