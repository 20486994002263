export const ProjetosLista = [
  {
    title: 'Crud',
    subtitle: 'ReactApp - JavaScript - Firebase - Css',
    text: 'Projeto criado no curso de frontend. Consiste em um gerenciador, onde você se cadastra, realiza o login e realiza postagens com o titulo e comentario.',
    github: 'https://github.com/DuHansen/Psci',
    deploy: 'https://psci.vercel.app/',
  },
  {//Projeto criado no curso de FrontEnd do Senac. Consiste em uma site institucional
    title: 'Site Advocacia',
    subtitle: 'ReactApp - React Router - JavaScript - Css',
    text: 'Este é um projeto de uma desafio para site institucional',
    github: 'https://github.com/DuHansen/advocacia',
    deploy: 'https://advocacia-nine.vercel.app/',
  },
  {
    title: 'Site Carros',
    subtitle: 'React - JavaScript - Css',
    text: 'Este é um projeto de uma desafio para site institucional',
    github: 'https://github.com/DuHansen/SiteBMW',
    deploy: 'https://site-bmw-one.vercel.app/',
  },
  {
    title: 'Lista de Noticias',
    subtitle: 'React - Consumo de API - React Router - Hooks',
    text: 'Este projeto é uma lista de notícias, que puxa as informações de uma API (imagem, Titulo, descrição), onde os dados são tratados e rotas são definidas (para detalhar mais a notícia).',
    github: 'https://github.com/DuHansen/2024-ads3f-appreactbase/tree/master',
    deploy: 'https://noticias-blush-omega.vercel.app/',
  },
]
